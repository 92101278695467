import Cookies from "js-cookie";

export const localStorageUtil = {
    getItem: (key) => {
        if (typeof window === "undefined") {
            return null;
        }
        try {
            const value = window.localStorage.getItem(key);
            if (value == "undefined") {
                return null;
            }
            try {
                return JSON.parse(value);
            } catch (e) {
                return value;
            }
        } catch (e) {
            console.error('Error reading from localStorage:', e);
            return null;
        }
    },
    setItem: (key, value) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.error('Error writing to localStorage:', e);
            // Fallback to cookies if localStorage is full
            cookieUtil.setCookie(key, JSON.stringify(value));
        }
    },
    removeItem: (key) => {
        try {
            window.localStorage.removeItem(key);
        } catch (e) {
            console.error('Error removing from localStorage:', e);
        }
    },
    clear: () => {
        try {
            window.localStorage.clear();
        } catch (e) {
            console.error('Error clearing localStorage:', e);
        }
    },
};

export const cookieUtil = {
    setCookie: (key, val) => {
        try {
            Cookies.set(key, val, {
                expires: 2,
            });
        } catch (e) {
            console.error('Error setting cookie:', e);
        }
    },
    getCookie: (key) => {
        try {
            return Cookies.get(key);
        } catch (e) {
            console.error('Error getting cookie:', e);
            return null;
        }
    },
};
