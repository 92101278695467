import { FlexContainer } from "@components/ui/flex_container";
import classNames from "classnames";
import React from "react";
import { DraggableCore } from "react-draggable";

import dynamic from "next/dynamic";
const AnimatePresence = dynamic(() => import("framer-motion").then((mod) => mod.AnimatePresence));

export default function DraggableModal({
    headerTitle,
    headerSubtitle,
    children,
    isOpen,
    onRequestClose,
    initialPosition = { x: 285, y: 90 },
    closable = true,
    maxWidth = 300,
}) {
    const [position, setPosition] = React.useState(initialPosition);

    const handleDrag = (e, ui) => {
        let { x, y } = position;
        console.log("drag", x, y, ui.deltaX, ui.deltaY);
        if (y < 0) {
            y = 0;
        }
        if (x < initialPosition.x) {
            x = initialPosition.x;
        }
        setPosition({
            x: x + ui.deltaX,
            y: y + ui.deltaY,
        });
    };
    return (
        <div className={classNames("draggable-modal", { visible: isOpen })}>
            <AnimatePresence>
                <DraggableCore
                    onStart={handleDrag}
                    onStop={handleDrag}
                    onDrag={handleDrag}
                    handle=".header"
                >
                    <div
                        style={{
                            position: "absolute",
                            left: position?.x?.toFixed(0).toString() + "px",
                            top: position?.y?.toFixed(0).toString() + "px",
                            boxShadow: "0px 0px 40px 10px #00000033",
                            maxWidth: maxWidth,
                            width: "95%",
                            zIndex: 6,
                            backgroundColor: "white",
                            borderRadius: 10,
                            overflow: "hidden",
                        }}
                    >
                        <FlexContainer
                            justifyContent="between"
                            alignItems="center"
                            style={{
                                margin: 0,
                                backgroundColor: "#eee",
                                borderBottom: "1px solid #ccc",
                                padding: "10px 20px",
                                cursor: "grab",
                            }}
                            className="header"
                        >
                            <FlexContainer column>
                                <h5 style={{ margin: 0, padding: 0, fontWeight: 600 }}>
                                    {headerTitle}
                                </h5>
                                {headerSubtitle && (
                                    <h6 style={{ margin: 0, padding: 0, fontSize: "0.85rem" }}>
                                        {headerSubtitle}
                                    </h6>
                                )}
                            </FlexContainer>
                            {closable && (
                                <a onClick={onRequestClose}>
                                    <img src="/img/close.svg" style={{ height: 15 }} />
                                </a>
                            )}
                        </FlexContainer>
                        <div style={{ margin: 20, marginTop: 5 }}>{children}</div>
                    </div>
                </DraggableCore>
            </AnimatePresence>
        </div>
    );
}
