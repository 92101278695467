const { AbilityBuilder, createMongoAbility } = require("@casl/ability");

function updateAbility(ability, user) {
    const { can, cannot, rules } = new AbilityBuilder(createMongoAbility);

    if (!user) {
        return;
    }
    cannot("manage", "all");
    
    const permissions = user?.permissions?.map?.((p) => ({
        action: p?.action,
        type: p?.type,
    })) || [];

    if (Array.isArray(permissions)) {
        permissions.forEach((permission) => {
            if (permission?.action && permission?.type) {
                can(permission.action, permission.type);
            }
        });
    }
    
    ability(user).update(rules);
}

export default updateAbility;
